import Vue from 'vue'
import Vuex from 'vuex'
import services from '@/helpers/services.js'

Vue.use(Vuex)

const idToken = window.localStorage.getItem('idToken')
const user = window.localStorage.getItem('user')

export default new Vuex.Store({
  state: {
    idToken: idToken ? JSON.parse(idToken) : null,
    user: user ? JSON.parse(user) : {}
  },
  getters: {
    idTokenGetter (state) {
      return state.idToken
    },
    userDetailGetter (state) {
      return state.user
    }
  },
  mutations: {
    authUser (state, userData) {
      state.idToken = userData.token
      window.localStorage.setItem('idToken', JSON.stringify(state.idToken))
    },
    addUserInfo (state, userData) {
      state.userInfo = userData
      window.localStorage.setItem('user', JSON.stringify(state.userInfo))
    }
  },
  actions: {
    async authUserLoginPage ({ commit }, response) {
      commit('authUser', response)
    },
    async userAdd ({ commit }, response) {
      commit('addUserInfo', response)
    },
    async signin ({ commit }, authData) {
      return new Promise((resolve, reject) => {
        services.login(authData)
          .then((response) => {
            resolve(response)
          },
          error => {
            reject(error)
          })
      })
    }
  },
  modules: {
  }
})
