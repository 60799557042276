<template>
    <div class="home_page">
      <HeaderComponent />
      <JoinComponent/>
        <FooterComponents/>
    </div>
  </template>
<script>
import FooterComponents from '@/components/FooterComponents.vue'
import HeaderComponent from '@/components/HeaderComponent'
import JoinComponent from '@/components/JoinComponent.vue'
export default {
  name: 'HomePage',
  data () {
    return {
    }
  },
  components: {
    HeaderComponent,
    FooterComponents,
    JoinComponent
  },
  created () {
  },

  methods: {}
}

</script>
