<template>
  <el-dialog :visible.sync="this.$root.orderDetailDialog" width="80%" :before-close="handleClose" center title="Захиалгийн дэлгэрэнгүй">
    <el-row>
      <el-col>
        <div class="txtcenter text_black">
          <h1>{{ orderDetailProp.track_code }}</h1>
        </div><br>
      </el-col>
      <el-col>
        <p class="txtcenter">дээрх track кодтой  захиалга</p><br><br>
      </el-col>
      <el-col v-for="progressItem,index in track_code_detail.Progress" :key="index">
        <div  class="no-wrap"  style="gap: 20px">
          <div class="icon_border">
            <i class="el-icon-takeaway-box"></i>
          </div>
          <div>
            {{ returnStatusValue(progressItem.name) }}
          </div>
          <div>-</div>
          <div>{{ progressItem.createdAt.split('T')[0] }}</div><br><br><br>
        </div>
      </el-col>
      <el-col>
        <el-button class="text_color_white" style="background-color: #007AFF;" @click="showDelivery = showDeliveryButton()">Хүргэлтээр авах</el-button>
        <el-button class="text_color_white" style="background-color: #555555;" @click="createProgress('goToGet')">Очиж авна</el-button>
        <el-button class="text_color_white" style="background-color: #EB9F24;" @click="showDeposit = showDepositButton()">Хадгалуулах</el-button><br><br>
      </el-col>
      <el-col v-if="showDelivery">
        <template>
          <el-radio v-for="(item, index) in this.addressList" :key="index" :v-model="address_id" :value="item.id">
            <el-button @click="buttonDisable()">{{ item.city + ", " + item.district + ", " + item.committee + ", " + item.detail_address }}</el-button><br><br>
          </el-radio>
        </template><br>
        <el-button type="success" :disabled="isDisabled" @click="createProgress('showDelivery')">
          Хадгалах
        </el-button>
      </el-col>
      <el-col v-if="showDeposit">
        <div class="block">
          <span class="demonstration">Эхлэх огноо: </span>
          <el-date-picker
            v-model="startDate"
            type="date"
            placeholder="Select date and time">
          </el-date-picker>
        </div><br>
        <div class="block">
          <span class="demonstration">Дуусах огноо: </span>
          <el-date-picker
            v-model="endDate"
            type="date"
            placeholder="Select date and time">
          </el-date-picker>
        </div>
        <el-button type="success" @click="createProgress('showDeposit')">
          Хадгалах
        </el-button>
      </el-col>
      <div>
        <AddressAddDialog v-if="this.$root.addressAddDialog" :addressSelect="clickedAddress"/>
      </div>
    </el-row>
  </el-dialog>
</template>

<script>
import services from '@/helpers/services'
import AddressAddDialog from './addressAddDialog.vue'
import {
  mapGetters
} from 'vuex'
export default {
  name: 'OrderDetailDialog',
  props: {
    orderDetailProp: Object
  },
  components: {
    AddressAddDialog
  },
  data () {
    return {
      track_code_detail: {
        Progress: []
      },
      customStatus: {},
      showDelivery: false,
      showDeposit: false,
      goToGet: false,
      addressList: [],
      clickedAddress: {},
      address_id: null,
      isDisabled: true,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: '',
      orderType: ''
    }
  },
  computed: {
    ...mapGetters([
      'userDetailGetter'
    ])
  },
  created () {
    this.viewAddress()
    this.getTrackCodeById()
  },
  methods: {
    returnStatusValue (data) {
      const result = this.$root.statusArray.find(element => element.value === data)
      if (result) {
        return result.label
      } else {
        return 'empty'
      }
    },
    handleClose () {
      this.$root.orderDetailDialog = false
    },
    viewAddress () {
      this.$root.fullscreenLoading = true
      services.viewAddress(this.userDetailGetter.id)
        .then((response) => {
          this.addressList = response.data
          this.$root.fullscreenLoading = false
        })
        .catch((error) => {
          console.error('Алдаа:', error.message)
        })
    },
    addressDetaiView (data) {
      this.clickedAddress = data
      this.$root.addressAddDialog = true
    },
    buttonDisable () {
      this.isDisabled = !this.isDisabled
    },
    createProgress (data) {
      if (data === 'showDelivery') {
        this.orderType = 'delivery'
      } else if (data === 'goToGet') {
        this.orderType = 'goToGet'
      } else if (data === 'showDeposit') {
        this.orderType = 'showDeposit'
      }
      this.$root.fullscreenLoading = true
      services.createProgress(this.orderType, this.orderDetailProp.id, this.address_id, this.startDate, this.endDate)
        .then((response) => {
          this.$alert('Таны захиалгийн төлөв амжилттай өөрчлөгдлөө. ', 'Амжилттай', {
            confirmButtonText: 'Ойлголоо',
            callback: action => {
              location.reload()
            }
          })
          this.$root.orderDetailDialog = true
        })
        .catch((error) => {
          this.$alert('Шинэчлэхэд алдаа гарлаа: ' + error, 'Алдаа', {
            confirmButtonText: 'Ойлголоо'
          })
        })
    },
    showDeliveryButton () {
      this.showDeposit = false
      this.goToGet = false
      return true
    },
    showDepositButton () {
      this.showDelivery = false
      this.showDeposit = false
      return true
    },
    goToGetButton () {
      this.showDeposit = false
      this.showDelivery = false
      return true
    },
    getTrackCodeById () {
      this.$root.fullscreenLoading = true
      services.getTrackCodeById(this.orderDetailProp.id)
        .then((response) => {
          this.track_code_detail = response.data
          this.$root.fullscreenLoading = false
        })
        .catch((error) => {
          console.error('Алдаа:', error.message)
        })
    }
  }
}
</script>

<style>.icon_border{
 width: 20px;
 height: 20px;
 background-color: #409eff;
 color: white;
 border-radius: 5px;
}
</style>
