<template>
  <div>
      <el-dialog title="TRACK CODE БҮРТГЭХ" :visible.sync="this.$root.trackCodeAddDialog" width="300px" :before-close="handleClose" center>
        <el-form class="text_center track_code_add" v-model="trackCodeAdd">
          <el-form-item>
            <span class="text_color font_format fw-500">TRACK CODE оруулна уу <sapn class="text_red">*</sapn></span>
            <el-input class="track_code_area_input p-5" v-model="trackCodeAdd.track_code" placeholder="TRACK CODE-оо оруулна уу"></el-input>
          </el-form-item>
          <el-form-item>
            <span class="text_color font_format fw-500">Та утасны дугаараа оруулна уу <sapn class="text_red">*</sapn></span>
            <el-input class="track_code_area_input p-5" v-model="trackCodeAdd.phone_number" placeholder="Та утасны дугаараа оруулна уу"></el-input>
          </el-form-item>
          <el-form-item>
            <span class="text_color font_format fw-500">Монголд хүлээн авах салбар <sapn class="text_red">*</sapn></span><br>
            <el-select class="track_code_area_input w-100 p-3" v-model="trackCodeAdd.user_select_branch" placeholder="Монголд хүлээн авах салбар">
              <el-option v-for="item,index in branches" :key="index" :label="item.branch_name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <span class="text_color font_format fw-500">Тайлбар</span><br>
            <el-input type="textarea" class="track_code_area_input w-100 p-3" v-model="trackCodeAdd.description" placeholder="Тайлбар">
              <el-option v-for="item,index in productTypeProp" :key="index" :label="item.name" :value="item.id  ">
              </el-option>
            </el-input>
          </el-form-item>
          <el-button  @click="addTrackCode(trackCodeAdd)" class="track_code_button">Track код бүртгэх</el-button>
        </el-form>
      </el-dialog>
  </div>
</template>

<script>
import services from '@/helpers/services'
import {
  mapGetters
} from 'vuex'

export default {
  props: {
    productTypeProp: Array
  },
  data () {
    return {
      trackCodeAdd: {
        track_code: '',
        phone_number: '',
        description: '',
        user_select_branch: null,
        progress_name: 'user_created'
      },
      branches: []
    }
  },
  computed: {
    ...mapGetters([
      'userDetailGetter'
    ])
  },
  created () {
    this.getBranch()
  },
  methods: {
    handleClose () {
      this.$root.trackCodeAddDialog = false
    },
    addTrackCode () {
      this.$root.fullscreenLoading = true
      services.addTrackCode(this.trackCodeAdd.track_code, this.trackCodeAdd.phone_number, this.trackCodeAdd.description, this.trackCodeAdd.user_select_branch, this.userDetailGetter.id, this.trackCodeAdd.progress_name)
        .then((response) => {
          this.$root.fullscreenLoading = false
          this.$alert('Таны track код амжилттай бүртгэгдлээ.', 'Амжилттай', {
            confirmButtonText: 'Ойлголоо',
            callback: action => {
              location.reload()
            }
          })
        })
        .catch((error) => {
          this.$root.fullscreenLoading = false
          this.$alert('Уучлаарай: ' + error, 'Алдаа', {
            confirmButtonText: 'Ойлголоо'
          })
        })
    },
    getBranch () {
      services.getBranch()
        .then((response) => {
          this.branches = response
        })
        .catch((error) => {
          console.error('Алдаа:', error.message)
        })
    }
  }
}
</script>

<style>
.track_code_area_input{
font-weight: bold;
color: rgb(0, 0, 0);
padding: 0px;
font-family: 'Geologica', sans-serif;
}
.track_code_area_input .el-input__inner{
background-color: #D9D9D9;
color: black;
font-weight: bold;
align-items: center;
font-family: 'Geologica', sans-serif;
font-size: 24px;
text-align: center;
}
.el-input ::placeholder{
color: rgb(148, 148, 148) !important;
font-size: 14px;
font-weight: 300;
}
.track_code_add{
line-height: 2.5;
}
</style>
