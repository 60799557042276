<template>
  <div>
    <div class="back_sticky">
      <el-image style="width: 140px; height: 140px" :src="banner_back" fit="cover"></el-image>
    </div>
    <div class="banner p-l-60 p-r-50">
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="item in 4" :key="item">
          <el-image class="banner_img" style="width: 100%; height: 100%" :src="img_url" fit="cover"></el-image>
        </el-carousel-item>
      </el-carousel>
      <div>
        <el-row class="banner_bottom_layout" justify="center" align="middle">
          <el-col :span="8" class="text-center">
            <span class="banner_text_title">Үнэ</span><br />
            <span class="banner_text_description bottom_text_size">хамгийн хямд</span>
          </el-col>
          <el-col :span="8" class="text-center">
            <span class="banner_text_title">Өдөр бүр</span><br />
            <span class="banner_text_description bottom_text_size">хүргэлттэй</span>
          </el-col>
          <el-col :span="8" class="text-center">
            <span class="banner_text_title">Хурдан</span><br />
            <span class="banner_text_description bottom_text_size">найрсаг</span>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import url from '@/assets/banner1.png'
import BannerBack from '@/assets/sky_back.png'

export default {
  name: 'HeaderComponent',
  data () {
    return {
      img_url: url,
      banner_back: BannerBack
    }
  }
}

</script>
<style>
  .banner_bottom_layout {
    display: flex;
    justify-content: space-around; /* Текстүүдийг хэвтээ чиглэлд голлуулна */
    align-items: center; /* Текстийг босоо чиглэлд голлуулна */
    background-color: white;
    padding: 25px;
    width: 88%;
    z-index: 10;
    top: -50px;
    left: 12px;
  }
  .text-center {
  text-align: center; /* Текстийг дотроос нь төвлөрүүлнэ */
}
  @media all and (min-width: 991px) {
    .banner_text_title {
      font-size: 24px;
      font-weight: bold;
    }
  }
  @media all and (max-width: 991px) {
    .bottom_text_size{
      font-size: 10px;
    }
  }
  .banner_text_title {
    color: #007AFF;
    font-weight: bold;
    align-items: center;
  }

  .banner_text_description {
    color: gray;
  }

  .banner {
    background-color: #CDE0E9;
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 400px;
    margin: 0;
  }

  .el-carousel__container {
    height: 600px !important;
    width: 90% !important;

  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }

  .back_sticky {
    z-index: -1;
    position: fixed;
    top: 200px;
    left: 100px;
  }

</style>
