import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/scss/main.scss'
import UserRegister from '@/assets/images/user_registred.jpeg'
import WearHouseIn from '@/assets/images/wearhouse_in.jpeg'
import UserIn from '@/assets/images/mongolia.png'
import * as VueGoogleMaps from 'vue2-google-maps'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(ElementUI)
Vue.config.productionTip = false

// VueGoogleMaps бүртгэх
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyD5yAPe69smGeq05VGdixR_gdMmpHpH2n8', // Google Maps API Key оруулна
    libraries: ['places'] // шаардлагатай сангуудыг нэмнэ
  }
})

// Валют форматлах filter
Vue.filter('formatCurrency', function (value) {
  return new Intl.NumberFormat().format(value) + '₮'
})

// App эхлүүлэх
new Vue({
  router,
  store,
  data: {
    trackCodeDetailDialog: false,
    trackCodeAddDialog: false,
    loginDialog: false,
    registerDialog: false,
    fullscreenLoading: false,
    orderDetailDialog: false,
    addressAddDialog: false,
    resetPasswordDialog: false,
    statusArray: [
      {
        id: 1,
        value: 'user_created',
        label: ' Хэрэглэгч бүртгэсэн',
        img_url: UserRegister
      },
      {
        id: 2,
        value: 'arrived_at_the_warehouse',
        label: ' Эрээн дах агуулах хүлээн авсан',
        img_url: WearHouseIn
      },
      {
        id: 3,
        value: 'received_by_branch',
        label: 'Монгол дах салбар хүлээн авсан',
        img_url: UserIn
      },
      {
        id: 4,
        value: 'received_by_user',
        label: ' Хэрэглэгч хүлээн авсан',
        img_url: UserIn
      },
      {
        id: 5,
        value: 'delivery',
        label: ' Хэрэглэгч хүргэлтээр авна',
        img_url: UserIn
      },
      {
        id: 6,
        value: 'goToGet',
        label: ' Хэрэглэгч салбараас очиж авна',
        img_url: UserIn
      },
      {
        id: 7,
        value: 'showDeposit',
        label: ' Хэрэглэгч салбар дээр хадгалуулна',
        img_url: UserIn
      }
    ]
  },
  render: h => h(App)
}).$mount('#app')
