<template>
  <div class="footer">
    <div class="footer-content">
      <el-row justify="space-between" align="top" class="footer-row">
        <!-- Лого хэсэг -->
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="footer-logo">
          <img @click="goToHome('/')" src="@/assets/tenger_logo.png" alt="Logo" class="logo" /><br><br><br>
        </el-col>

        <!-- Холбоосууд -->
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="footer-links">
          <ul>
            <li><a href="#">Бидний тухай</a></li>
            <li><a href="#">Үйлчилгээний нөхцөл</a></li>
            <li><a href="#">Ачааны төлөв шалгах, хүргэлтийн журам</a></li>
            <li><a href="#">Нууцлалын бодлого</a></li>
            <li class="cursor"><a @click="goToAddress('/branches')">Холбоо барих</a></li>
          </ul><br>
        </el-col>

        <!-- Холбоо барих -->
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="footer-contact">
          <p>
            <a target="_blank" class="text_color_white" href="https://www.facebook.com/profile.php?id=100070131997091"><i class="fa-brands fa-square-facebook"></i>VIVA Training</a>
          </p>
          <p>
            <a target="_blank" class="text_color_white" href="https://www.instagram.com/viva_cargo_mongolia/?utm_source=ig_web_button_share_sheet"><i class="fa-brands fa-instagram"></i>viva_cargo_mongolia</a>
          </p>
          <p><a href="tel:+97699333113" class="text_color_white"><i class="fa-solid fa-phone"></i>+976 9933 3113</a></p>
          <p><a href="mailto:info@test.com" class="text_color_white"><i class="fa-regular fa-envelope"></i> info@test.com</a></p>
        </el-col>
      </el-row>
    </div>
    <div class="footer-bottom">
      <p>Оюуны өмчөөр хамгаалагдсан &copy; Тэнгэр карго ХХК</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterComponent',
  data () {
    return {
      windowWidth: window.innerWidth
    }
  },
  methods: {
    handleResize () {
      this.windowWidth = window.innerWidth
    },
    goToAddress (url) {
      if (this.$route.path !== url) {
        this.$router.push('/branches')
      }
    },
    goToHome (url) {
      if (this.$route.path !== url) {
        this.$router.push('/')
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style>
.footer {
  background-color: #0c265c;
  color: white;
  font-family: 'Geologica', sans-serif;
}

.footer-content {
  padding: 40px 20px;
}

.footer-row {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo {
  text-align: left;
}

.footer-logo .logo {
  width: 150px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-contact p {
  margin: 10px 0;
  font-size: 14px;
}

.footer-contact i {
  margin-right: 10px;
}

.footer-bottom {
  background-color: #091c40;
  text-align: center;
  padding: 10px 0;
  font-size: 12px;
}

</style>
