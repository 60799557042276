import {
  mainRequests
} from './custom'
export default {
  searchTrackCode (trackCode) {
    return new Promise((resolve, reject) => {
      mainRequests
        .get(`/trackCodes/${trackCode}`)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  getProductType () {
    return new Promise((resolve, reject) => {
      mainRequests
        .get('/productTypes')
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  addTrackCode (trackCode, phoneNumber, descriptionTrackCode, userSelectBranch, id, progressName, description) {
    return new Promise((resolve, reject) => {
      mainRequests
        .post('/trackCodes', { track_code: trackCode, phone_number: phoneNumber, description: descriptionTrackCode, user_select_branch: userSelectBranch, created_user_id: id, name: progressName })
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  getFAQ () {
    return new Promise((resolve, reject) => {
      mainRequests
        .get('/faqs')
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  login (phoneNumber, password) {
    return new Promise((resolve, reject) => {
      mainRequests
        .post('/login', { phone1: phoneNumber, password: password })
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  register (phoneNumber, password) {
    return new Promise((resolve, reject) => {
      mainRequests
        .post('/register', { phone1: phoneNumber, password: password })
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  updateUser (id, firstName, lastName, email, phone1, phone2, address) {
    return new Promise((resolve, reject) => {
      mainRequests
        .putAuth(`/users/${id}`, { firstName, lastName, email, phone1, phone2, address })
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  searchTrackCodeByPhoneNumber (phoneNumber) {
    return new Promise((resolve, reject) => {
      mainRequests
        .get(`/trackCodesPhone/${phoneNumber}`)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  getBranch () {
    return new Promise((resolve, reject) => {
      mainRequests
        .get('/branches')
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  searchTrackCodeByPhoneNumberOrderBy (phoneNumber) {
    return new Promise((resolve, reject) => {
      mainRequests
        .get(`/trackCodesPhoneOrderBy/${phoneNumber}`)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  getAddressByOrder (userId) {
    return new Promise((resolve, reject) => {
      mainRequests
        .getAuth(`/addressByOrder/${userId}`)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  createAddress (nickName, detailAddress, lng, lat, userId) {
    return new Promise((resolve, reject) => {
      mainRequests
        .postAuth('/address', {
          nick_name: nickName,
          detail_address: detailAddress,
          user_id: userId,
          lng: lng,
          lat: lat
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  updateAddress (id, nickName, detailAddress, lng, lat) {
    return new Promise((resolve, reject) => {
      mainRequests
        .putAuth(`/address/${id}`, {
          nick_name: nickName,
          detail_address: detailAddress,
          lng: lng,
          lat: lat
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  viewAddress (userId) {
    return new Promise((resolve, reject) => {
      mainRequests
        .getAuth(`/address/${userId}`)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  getOrderDetail (id) {
    return new Promise((resolve, reject) => {
      mainRequests
        .get(`/trackCodes/${id}`)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  createProgress (name, trackCodeId, addressId, startDate, endDate) {
    return new Promise((resolve, reject) => {
      mainRequests
        .postAuth('/progress', { name: name, track_code_id: trackCodeId, address_id: addressId, start_date: startDate, end_date: endDate })
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  getTrackCodeById (id) {
    return new Promise((resolve, reject) => {
      mainRequests
        .getAuth(`/trackCodesId/${id}`)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  },
  getTrackCodeByPhoneOrderByHistory (phoneNumber) {
    return new Promise((resolve, reject) => {
      mainRequests
        .get(`/trackCodesPhoneOrderByHistory/${phoneNumber}`)
        .then(res => resolve(res))
        .catch(err => reject(err))
    })
  }
}
