<template>
  <div class="map_layout">
    <el-row>
      <el-col :xs="24" :sm="24" :md="10" :lg="8" :xl="7" class="text_size">
      <el-card>
        <h2>Салбарын жагсаалт</h2>
        <div class="branch_detail m-t-50 text-align">
         <span> <b><h2>Бөхийн өргөө салбар</h2></b></span>
         <div class="txtleft">
          <i class="fa-solid fa-clock"></i>&nbsp;
          <b>Цагийн хуваарь:</b><br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Даваа, Лхагва, Пүрэв: 11:00-18:00<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Бямба, Ням: 10:00-18:00<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мягмар: Амарна<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Баасан: Дотоод ажилтай<br>
         </div>
        <div class="txtleft">
          <i class="fa-solid fa-map-marker"></i>&nbsp;
          <b>Дэлгэрэнгүй хаяг:</b><br>
          <span class="no-wrap">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<p>Бөхийн өргөөний баруун урд, Баянзүрх захын зүүн талд Эрхэт худалдааны төв 2 давхарт 2-17,18 тоот</p>
          </span>
        </div>
        <div class="txtleft">
          <i class="fa-solid fa-phone"></i>
          <b> Утас: </b>
          <p>
            <a href="tel:+97699333113" class="text_color_white">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+976 9933 3113
            </a>
          </p>
        </div>
        </div>
      </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="14" :lg="16" :xl="17">
      <div id="map" style="width: 100%; height: 800px;">
        <GmapMap
          :center="{ lat: 47.9162, lng: 106.9350834 }"
          :zoom="12"
          style="width: 100%; height: 100%"
        >
          <GmapMarker
            :position="{ lat: 47.9162, lng: 106.9350834 }"
            :clickable="true"
            :draggable="true"
            @click="markerClicked()"
          >
          </GmapMarker>
        </GmapMap>
    </div>
  </el-col>
  </el-row>
</div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {
    markerClicked () {
      this.$confirm('Та манай бөхийн өргөө салбартай холбоо барихыг хүсэж байна уу?', 'Хээе сайн уу', {
        confirmButtonText: 'Тийм',
        cancelButtonText: 'Буцах',
        type: 'success',
        center: true
      }).then(() => {

      }).catch(() => {

      })
    }
  }
}
</script>

<style scoped>
#map {
  width: 100%;
  height: 400px;
}
.map_layout{
  border-top: solid #007AFF;
  text-align: center;
}
.branch_detail{
  background-color: #007AFF;
  color: white;
  border-radius: 10px;
  padding: 18px;
  line-height: 2;
}
.branch_button{
  color: white;
}
@media all and (min-width: 991px) {
  .text_size {
    font-size: 17px;
  }
}
@media all and (max-width: 991px) {
  .text_size{
    font-size: 12px;
  }
}
</style>
