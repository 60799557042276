<template>
    <div id="app" class="home_page">
      <HeaderComponent />
        <ProfileComponents/>
        <FooterComponents class="m-t-100"/>
    </div>
  </template>
<script>
import ProfileComponents from '@/components/ProfileComponent.vue'
import FooterComponents from '@/components/FooterComponents.vue'
import HeaderComponent from '@/components/HeaderComponent'
export default {
  name: 'HomePage',
  data () {
    return {
    }
  },
  components: {
    HeaderComponent,
    ProfileComponents,
    FooterComponents
  },
  created () {
  },

  methods: {}
}

</script>
