<template>
    <div class="count_area m-t-100 m-b-100">
      <el-row type="flex" class="row-bg m-t-80" justify="center">
        <el-col :xs="22" :sm="22" :md="18" :lg="18" :xl="18">
            <el-card class="p-30">
                <el-row :gutter="20">
                    <el-col :xs="22" :sm="22" :md="6" :lg="6" :xl="6" class="count_item">
                        <span class="count_big_size">4450</span><br/>
                        <span class="count_big_desc">амжилттай захиалга</span>
                    </el-col>
                    <el-col :xs="22" :sm="22" :md="6" :lg="6" :xl="6" class="count_item">
                        <span class="count_big_size">1200</span><br/>
                        <span class="count_big_desc">хэрэглэгч</span>
                    </el-col>
                    <el-col :xs="22" :sm="22" :md="6" :lg="6" :xl="6" class="count_item">
                        <span class="count_big_size">40+</span><br/>
                        <span class="count_big_desc">ажилтан</span>
                    </el-col>
                    <el-col :xs="22" :sm="22" :md="6" :lg="6" :xl="6" class="count_item">
                        <span class="count_big_size">1500</span><br/>
                        <span class="count_big_desc">хүргэлт</span>
                    </el-col>
                </el-row>
            </el-card>
        </el-col>
      </el-row>
    </div>
  </template>

<script>
export default {
  name: 'HeaderComponent',
  data () {
    return {

    }
  },
  methods: {

  }
}

</script>
  <style>
  .count_area{
    text-align: center;
  }
  .count_item{
    text-align: center;
  }
  .count_big_size{
    font-family: 'Geologica', sans-serif;
    font-weight: bold;
    color: #007AFF;
    font-size: 60px;
  }
  .count_big_desc{
    font-family: 'Geologica', sans-serif;
    font-size: 20px;
    font-family: 'Geologica', sans-serif;
    font-weight: 400;
    color: #007AFF;
  }
  </style>
