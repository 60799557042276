<template>
    <div class="faq_area m-t-150 p-l-250">
      <div class="faq_area_title">
        <el-row >
          <el-col :xs="22" :sm="22" :md="17" :lg="17" :xl="15">
              <span class="faq_title_left">Түгээмэл асуулт <span class="title_color">хариулт</span></span>
          </el-col>
          <el-col :xs="22" :sm="22" :md="22" :lg="5" :xl="5" class="faq_right m-t-20">
              <span class="faq_title_right">Хэрэглэгчдээс хамгийн түгээмэл ирдэг асуултуудыг танд хүргэлээ.</span>
          </el-col>
        </el-row>
      </div>
      <el-row class="row-bg m-t-60" >
        <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="20">
          <el-collapse>
            <div v-for="item,index in faqList" :key="index">
              <el-collapse-item :title="item.answer">
              <div>{{ item.question }}</div>
            </el-collapse-item>
            </div>
          </el-collapse>
        </el-col>
      </el-row>
    </div>
</template>

<script>
import services from '@/helpers/services'

export default {
  name: 'HeaderComponent',
  data () {
    return {
      faqList: []
    }
  },
  created () {
    this.getFAQ()
  },
  methods: {
    getFAQ () {
      services.getFAQ()
        .then((response) => {
          this.faqList = response
        })
        .catch((error) => {
          console.error('Алдаа:', error.message)
        })
    }
  }
}

</script>
  <style>
    @media all and (min-width: 991px) {
    .faq_title_right {
      font-size: 20px;
    }
  }
  @media all and (max-width: 991px) {
    .faq_title_right{
      font-size: 10px;
    }
  }
  .faq_area_title .faq_title_left{
    font-family: 'Geologica', sans-serif;
    font-size: 40px;
    font-weight: 500;
    color: #3C3B3B;
    float: left;
  }
  .faq_title_left .title_color{
    color: #007AFF;
  }
  .faq_title_right{
    color: #9c9c9c;
    font-family: 'Geologica', sans-serif;
    font-weight: 400;
  }
  .faq_right{
    float: right;
    width: 200px;
    text-align: right;
  }
  .el-collapse-item__header{
    border: 1px solid #EBEEF5;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
  }
  .el-collapse-item .is-active{
   border: none;
   /* background-color: #ccc; */
  }
  .el-collapse-item__header.is-active {
    color: #007AFF !important;
  }
  .el-collapse-item__content{
    margin-left: 20px;
  }
  .el-collapse{
    border: none !important;
  }
  </style>
