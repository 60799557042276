<template>
  <el-row type="flex">
    <el-col :span="4">
      <div class="back_sticky">
        <el-image style="width: 140px; height: 140px" :src="banner_back" fit="cover"></el-image>
      </div>
    </el-col>
    <el-col :span="16" class="user_unfo">
      <div class="font_format now-wrap">Сайн байна уу. {{ userDetailGetter.phone1 }} дугаартай хэрэглэгч.</div><br>
      <el-tabs>
        <!-- Хэрэглэгчийн мэдээлэл -->
        <el-tab-pane>
          <span slot="label">
            <i class="el-icon-user-solid font_format"></i>
            Хэрэглэгчийн мэдээлэл
          </span>
          <el-row>
            <el-col :span="24">   <div class="profile_shape_zone">
              <div class="profile_shape">
                <b>46</b><br/>
                <span>Нийт захиалгын тоо</span>
              </div> <br/>
              <div class="profile_shape">
                <b>46</b><br/>
                <span>Нийт захиалгын тоо</span>
              </div>
              <div class="profile_shape">
                <b>46</b><br/>
                <span>Нийт захиалгын тоо</span>
              </div>
            </div>
            </el-col>
            <el-col :span="22" :offset="1">
              <el-form :model="userDetailGetter" label-width="100px" :label-position="'left'">
                <el-form-item label="Овог">
                  <el-input placeholder="Хоосон" v-model="userDetailGetter.firstName" :disabled="isDisabled"></el-input>
                </el-form-item>
                <el-form-item label="Нэр">
                  <el-input placeholder="Хоосон" v-model="userDetailGetter.lastName" :disabled="isDisabled"></el-input>
                </el-form-item>
                <el-form-item label="Утас">
                  <el-input placeholder="Хоосон" v-model="userDetailGetter.phone1" :disabled="isDisabled" prop="phone1">
                  </el-input>
                </el-form-item>
                <el-form-item label="Нэмэлт утас">
                  <el-input placeholder="Хоосон" v-model="userDetailGetter.phone2" :disabled="isDisabled"></el-input>
                </el-form-item>
                <el-form-item label="Мэйл хаяг">
                  <el-input placeholder="Хоосон" v-model="userDetailGetter.email" :disabled="isDisabled"></el-input>
                </el-form-item>
                <p class="text_grey" v-if="this.isDisabled === true">Хаягийн мэдээлэл</p><br>
                <div v-if="addressList && this.isDisabled === true" class="p-b-20">
                  <el-button size="mini" type="primary" v-for="(item, index) in addressList" :key="index" class="m-l-10"
                    @click="addressDetaiView(item)">
                    {{ item.nick_name || 'Nick name байхгүй' }}
                  </el-button>
                </div>
              </el-form>
              <el-button class="w-100 m-l-10" v-if="this.isDisabled === true" type="warning" size="mini" @click="addAddress()">Хаяг нэмэх</el-button>
              <el-button  v-if="this.isDisabled === true" class="button_background_color w-100 m-t-10" size="mini" @click="buttonDisable()">
                Мэдээлэл шинэчлэх</el-button>
              <el-button v-if="this.isDisabled === false" class="w-100 m-t-10" type="success" size="mini" @click="save()">Хадгалах</el-button>
              <el-button class="w-100 m-t-10" size="mini" type="danger">Нууц үг шинэчлэх</el-button>
              <el-button class="w-100 m-t-10" size="mini" @click="logout()">Гарах</el-button>
            </el-col>
          </el-row>
        </el-tab-pane>
        <!-- Захиалгийн явц -->
        <el-tab-pane>
          <span slot="label">
            <i class="el-icon-user-solid font_format"></i>
            Захиалгийн явц
          </span>
          <OrderProgressComponent :trackCodesList="track_code_detail"/>
        </el-tab-pane>
        <!-- Захиалгийн түүх -->
        <el-tab-pane>
          <span slot="label">
            <i class="el-icon-user-solid font_format"></i>
            Захиалгийн түүх
          </span>
          <OrderProgressComponent :trackCodesList="track_code_detail_history"/>
        </el-tab-pane>
      </el-tabs>
    </el-col>
    <div>
      <AddressAddDialog v-if="this.$root.addressAddDialog" :addressSelect="clickedAddress" />
    </div>
  </el-row>
</template>

<script>
import Profile from '@/assets/banner1.png'
import BannerBack from '@/assets/sky_back.png'
import services from '@/helpers/services'
import AddressAddDialog from './Dialog/addressAddDialog.vue'
import OrderProgressComponent from '@/components/OrderProgressComponent.vue'
import {
  mapGetters
} from 'vuex'

export default {
  components: {
    AddressAddDialog,
    OrderProgressComponent
  },
  name: 'ProfileComponent',
  data () {
    return {
      banner_back: BannerBack,
      profile: Profile,
      isDisabled: true,
      clickedAddress: {},
      addressList: [],
      track_code_detail: [],
      track_code_detail_history: []
    }
  },
  computed: {
    ...mapGetters([
      'userDetailGetter'
    ])
  },
  created () {
    this.viewAddress()
    this.searchTrackCodeByPhoneNumberOrderBy()
    this.getTrackCodeByPhoneOrderByHistory()
  },
  methods: {
    buttonDisable () {
      this.isDisabled = !this.isDisabled
    },
    save () {
      services.updateUser(this.userDetailGetter.id, this.userDetailGetter.firstName,
        this.userDetailGetter.lastName, this.userDetailGetter.email, this.userDetailGetter.phone1,
        this.userDetailGetter.phone2, this.userDetailGetter.city, this.userDetailGetter.district,
        this.userDetailGetter.committee, this.userDetailGetter.detail_address, this.userDetailGetter.description)
        .then((response) => {
          this.$alert('Таны мэдээлэл амжилттай засагдлаа.', 'Амжилттай', {
            confirmButtonText: 'Ойлголоо',
            callback: action => {
              this.isDisabled = true
            }
          })
        })
        .catch((error) => {
          this.$alert('Шинэчлэхэд алдаа гарлаа: ' + error, 'Алдаа', {
            confirmButtonText: 'Ойлголоо'
          })
        })
    },
    addAddress () {
      this.$root.addressAddDialog = true
      this.clickedAddress = null
    },
    addressDetaiView (data) {
      this.clickedAddress = data
      this.$root.addressAddDialog = true
    },
    viewAddress () {
      this.$root.fullscreenLoading = true
      services.viewAddress(this.userDetailGetter.id)
        .then((response) => {
          this.addressList = response.data
          this.$root.fullscreenLoading = false
        })
        .catch((error) => {
          console.error('Алдаа:', error.message)
        })
    },
    searchTrackCodeByPhoneNumberOrderBy () {
      this.$root.fullscreenLoading = true
      services.searchTrackCodeByPhoneNumberOrderBy(this.userDetailGetter.phone1)
        .then((response) => {
          this.track_code_detail = response.data
          this.$root.fullscreenLoading = false
        })
        .catch((error) => {
          console.error('Алдаа:', error.message)
        })
    },
    getTrackCodeByPhoneOrderByHistory () {
      this.$root.fullscreenLoading = true
      services.getTrackCodeByPhoneOrderByHistory(this.userDetailGetter.phone1)
        .then((response) => {
          this.track_code_detail_history = response.data
          this.$root.fullscreenLoading = false
        })
        .catch((error) => {
          console.error('Алдаа:', error.message)
        })
    },
    logout () {
      localStorage.clear()
      this.$router.push({
        path: '/'
      }
      )
      location.reload()
    }
  }
}

</script>

<style>
  .el-tabs__item i {
    background: #007AFF;
    padding: 7px;
    color: white;
    border-radius: 8px;
  }
  .el-form-item__label{
    font-weight: 500;
  }

  .back_sticky {
    margin-top: -50px;
    left: 10px;
  }

  .user_unfo {
    margin-top: 50px;
  }

  .img_border {
    border-radius: 10px;
  }

  .profile_shape {
    background-color:#007AFF;
    color: white;
    height: 150px;
    text-align: center;
    align-items: center;
    border-radius: 8px;
    width: 150px;
    justify-items: center;
    margin: 3%;
    align-content: center;
    margin-right: 10px;
  }

  .home_page {
    text-align: left !important;
  }
  .profile_shape_zone{
    display: flex;
  }
</style>
