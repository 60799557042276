<template>
  <div class="calculate_layout">
    <el-row class="m-t-50 m-b-50">
      <el-col :offset="offsetResponsive()" :xs="20" :sm="20" :md="5" :lg="5" :xl="5">
       <b> Урьдчилсан байдлаар үнэ тооцоолох</b>
        <el-form :model="calculateForm" :rules="rules" ref="ruleForm" label-width="120px" label-position="top"
          class="demo-ruleForm">
          <el-form-item label="Ачааны хэмжээ" prop="resource">
            <el-radio-group v-model="calculateForm.size">
              <el-radio v-for="item in options" :key="item.value" :label="item.value" class="custom-radio">
                <img :src="item.image" alt="radio-img" class="radio-image" /> <br />
                <span class="radio-label">{{ item.label }}</span>
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Ачааны төрөл" prop="resource" v-if="this.calculateForm.size== 'small'">
            <el-radio-group v-model="calculateForm.type">
              <el-radio v-for="item in option_types" :key="item.value" :label="item.value" class="custom-radio">
                <img :src="item.image" alt="radio-img" class="radio-image" /> <br />
                <span class="radio-label">{{ item.label }}</span>
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="Жин" prop="resource">
            <el-input-number class="track_code_area_input" :controls="false" v-model="calculateForm.mass" controls-position="right" :min="1" :max="10000"></el-input-number> <b class="m-l-20" v-if="calculateForm.size === 'small'"> гр</b><b class="m-l-20" v-if="calculateForm.size === 'big'"> kг</b><br/>
            <span class="desc">Та барааныхаа жинг кг- аар оруулахыг анхаарна уу.</span>
          </el-form-item>
          <el-form-item label="Ачааны урт өргөн өндөрийг оруулна уу /СМ/" prop="resource" v-if="this.calculateForm.size==='big'">
            <el-row>
              <span>Урт</span>
              <span class="m-l-90">Өргөн</span>
              <span class="m-l-90">Өндөр</span><br/>
                <el-input-number class="track_code_area_input" style="width: 90px;" :controls="false" v-model="calculateForm.urt" :min="1" :max="10000"></el-input-number> <b>см</b>
                <el-input-number class="track_code_area_input m-l-20" style="width: 90px;" :controls="false" v-model="calculateForm.urgun" :min="1" :max="10000"></el-input-number> <b>см</b>
                <el-input-number class="track_code_area_input m-l-20" style="width: 90px;" :controls="false" v-model="calculateForm.undur" :min="1" :max="10000"></el-input-number> <b>см</b><br/>
                <span class="desc">Та барааныхаа харьцааг СМ оруулахыг анхаарна уу.</span>
            </el-row>
          </el-form-item>
          <el-form-item>
    <el-button type="primary" class="track_code_button"  @click="returnPrice()">Тооцоолох</el-button>
  </el-form-item>
        </el-form>
      </el-col>
      <el-col  :offset="2" :xs="20" :sm="20" :md="10" :lg="10" :xl="10">
        <div class="calculate_result">
          <b>Тооцоолсон үнэ</b><br/>
          <div v-if="this.calculateForm.size==='big'">Урт:<b>{{ this.calculateForm.urt }}см</b>  Өргөн: <b>{{ this.calculateForm.urgun }}см</b> Өндөр: <b>{{ this.calculateForm.undur }}см</b></div>
          <br/>
          <el-image
            style="width: 100px; height: 100px"
            :src="this.calculateForm.type === 'box' ? 'https://cdn-icons-png.flaticon.com/512/2989/2989545.png' : 'https://cdn-icons-png.flaticon.com/512/5337/5337564.png'"
            fit="fill"></el-image><br/>
            <!-- {{ this.calculateForm.urt * this.calculateForm.urgun * this.calculateForm.undur }} -->
            <!-- {{ this.calculateForm.mass / (this.calculateForm.urt * this.calculateForm.urgun * this.calculateForm.undur) }} -->
            <b class="no-wrap">Урьдчилсан үнэ: &nbsp;<p class="paymentColor">{{returnPrice()}}</p>&nbsp;₮</b><br/>
            <span class="calculate_description line_height1">Энэхүү үнэ эцсийн үнэ биш бөгөөд эрхэм харилцагч та үйлчилгээний амжилттай холбогднуу</span>
          </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ReduceImage from '../assets/cube.png'
export default {
  props: {},
  mounted () {},
  data () {
    return {
      reduceImageUrl: ReduceImage,
      windowWidth: window.innerWidth,
      minMass: 2000,
      calculateForm: {
        size: '',
        type: '',
        mass: 1,
        urt: null,
        urgun: null,
        undur: null,
        delivery: false,
        resource: '',
        desc: ''
      },
      options: [{
        value: 'small',
        label: 'Жижиг',
        image: 'https://cdn-icons-png.flaticon.com/512/1279/1279594.png'
      },
      {
        value: 'big',
        label: 'Том ',
        image: 'https://cdn-icons-png.flaticon.com/512/2990/2990027.png'
      }
      ],
      option_types: [
        {
          value: 'pack',
          label: 'Ууттай',
          image: 'https://cdn-icons-png.flaticon.com/512/5337/5337564.png'
        },
        {
          value: 'box',
          label: 'Хайрцагтай',
          image: 'https://cdn-icons-png.flaticon.com/512/2989/2989545.png'
        }
      ],
      rules: {
        size: [{
          required: true,
          message: 'Please input Activity name',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 5,
          message: 'Length should be 3 to 5',
          trigger: 'blur'
        }
        ]
      }
    }
  },
  methods: {
    offsetResponsive () {
      if (this.windowWidth > 900) {
        return 4
      } else {
        return 2
      }
    },
    returnPrice () {
      if (this.calculateForm.size === 'big') {
        const v = (this.calculateForm.undur * this.calculateForm.urgun * this.calculateForm.urt) / 1000000
        const gramm = (this.calculateForm.mass * 1000)
        const p = gramm / v
        console.log(p, v, 'ee')
        if (p < 300) {
          return v * 650 * 495
        } else if (p >= 301 && p <= 600) {
          return v * 750 * 495
        } else if (p >= 601 && p <= 900) {
          return v * 800 * 495
        } else if (p >= 901) {
          return v * 850 * 495
        }
      } else if (this.calculateForm.size === 'small') {
        if (this.minMass >= this.calculateForm.mass) {
          return 5000
        } else {
          if (this.calculateForm.type === 'pack') {
            return this.calculateForm.mass * 2
          } else if (this.calculateForm.type === 'box') {
            return this.calculateForm.mass * 2.5
          }
        }
      }
    }
  }
}

</script>

<style scoped>
.calculate_description{
  color: gray;
}
.calculate_layout{
  border-top: solid #007AFF;
}
  svg {
    max-width: 100%;
  }
.calculate_result{
background-color: #D4E6FA;
padding: 10%;
text-align: center;
line-height: 4;
}
</style>
