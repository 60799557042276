<template>
    <div id="app" class="home_page">
      <HeaderComponent />
      <BranchesComponent/>
        <FooterComponents/>
    </div>
  </template>
<script>
import FooterComponents from '@/components/FooterComponents.vue'
import HeaderComponent from '@/components/HeaderComponent'
import BranchesComponent from '@/components/BranchesComponent.vue'
export default {
  name: 'HomePage',
  data () {
    return {
    }
  },
  components: {
    HeaderComponent,
    FooterComponents,
    BranchesComponent
  },
  created () {
  },

  methods: {}
}

</script>
