<template>
  <div class="track_code_result">
    <el-dialog title="Хайлт" :visible.sync="this.$root.trackCodeDetailDialog" width="300px" :before-close="handleClose"
      center>
      <div class="track_code_area"> {{ trackCodeDetailProp.track_code }}</div>
      <div v-if="this.trackCodeDetailProp.errorrMessage === 1">
        <img class="m-t-20" src="@/assets/images/search.jpeg"
          height="120px">
        <div
          class="no-wrap content_justify_center text_color font_format m-t-20">
          <span> Уучлаарай<br> дээрх кодтой бараа манай системд <br><span class="text_red"><b>бүртгэгдээгүй</b></span>
            байна. </span>
        </div><br>
    </div>
    <div v-else>
    <Barcode :value="trackCodeDetailProp.track_code" />
    <div class="m-t-40">Үр дүн <br/>
      <img class="m-t-20" :src="this.customObject.img_url" height="120px">
      <div class="content_justify_center text_color font_format m-t-20">
        <p class="useg_taslahgvi_mur_unagaah">Таны захиалга <span class="text_blue_color"> <b> {{ this.customObject.label }} </b></span> төлөвтэй байна.</p>
      </div>
    </div><br><br>
      <el-button v-if="this.trackCodeDetailProp.errorrMessage !== 1" @click="viewOrderDetail(trackCodeDetailProp)" type="text" class="font_format">Захиалгийн дэлгэрэнгүй</el-button>
    </div>
    </el-dialog>
    <OrderDetailDialog v-if="this.$root.orderDetailDialog" :orderDetailProp="selectedOrder"/>
    <LoginDialog v-if="true"></LoginDialog>
  </div>
</template>

<script>
import Barcode from '../BarCodeComponents.vue'
import OrderDetailDialog from './orderDetailDialog.vue'
import LoginDialog from './loginDialog.vue'
import {
  mapGetters
} from 'vuex'

export default {
  props: {
    trackCodeDetailProp: Object
  },
  data () {
    return {
      customObject: {},
      track_code: null,
      selectedOrder: {}
    }
  },
  computed: {
    ...mapGetters([
      'idTokenGetter'
    ])
  },
  components: {
    Barcode,
    OrderDetailDialog,
    LoginDialog
  },
  created () {
    this.objectReplace()
  },
  methods: {
    objectReplace () {
      this.$root.statusArray.forEach(element => {
        if (this.trackCodeDetailProp.Progress[0].name === element.value) {
          this.customObject = element
        }
      })
    },
    handleClose () {
      this.$root.trackCodeDetailDialog = false
    },
    trackCodeAddView () {
      this.$root.trackCodeDetailDialog = false
      this.$root.trackCodeAddDialog = true
    },
    viewOrderDetail (data) {
      if (!this.idTokenGetter) {
        this.$alert('Та нэвтэрч захиалгийн дэлгэрэнгүйг харна уу.', 'НЭВТРЭХ', {
          confirmButtonText: 'Нэвтрэх',
          callback: action => {
            this.$router.push({
              path: '/'
            })
            this.$root.loginDialog = true
          }
        })
      } else {
        this.selectedOrder = data
        this.$root.orderDetailDialog = true
      }
    }
  }
}

</script>

<style>
.track_code_area {
  background-color: #D9D9D9;
  font-size: 36px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  padding: 8px;
  font-family: 'Geologica', sans-serif;
}

.track_code_result {
  text-align-last: center;
  font-weight: 18px;
  font-family: 'Geologica', sans-serif;
}
</style>
