<template>
  <div>
    <el-table v-if="this.trackCodesList" :data="this.trackCodesList">
      <el-table-column label="№" type="index"></el-table-column>
      <el-table-column label="Бүртгэсэн огноо">
          <template slot-scope="scope">{{ scope.row.createdAt.split('T')[0] }}</template>
      </el-table-column>
      <el-table-column label="Track код" prop="track_code"></el-table-column>
      <el-table-column label="Төлөв" v-if="this.trackCodesList.Progress">
        {{ returnStatusValue(this.trackCodesList.Progress[0].name) }}
      </el-table-column>
      <el-table-column label="Үнэ"></el-table-column>
      <el-table-column label="Үйлдэл">
          <template slot-scope="scope">
          <a @click="viewOrderDetail(scope.row)" class="cursor">Дэлгэрэнгүй</a>
          </template>
      </el-table-column>
    </el-table>
    <div>
        <OrderDetailDialog v-if="this.$root.orderDetailDialog" :orderDetailProp="selectOrder" />
    </div>
  </div>
</template>

<script>
import OrderDetailDialog from './Dialog/orderDetailDialog.vue'

export default {
  data () {
    return {
      selectOrder: {}
    }
  },
  props: {
    trackCodesList: Array,
    trackCodeDetailProp: Object
  },
  components: {
    OrderDetailDialog
  },
  created () {

  },
  methods: {
    viewOrderDetail (data) {
      this.selectOrder = data
      this.$root.orderDetailDialog = true
    },
    returnStatusValue (data) {
      const result = this.$root.statusArray.find(element => element.value === data)
      if (result) {
        return result.label
      } else {
        return 'empty'
      }
    }
  }
}
</script>

<style>

</style>
