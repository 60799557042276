<template>
  <div id="app" class="home_page">
    <HeaderComponent />
    <el-row>
      <el-col :xs="20" :sm="20" :md="12" :lg="10" :xl="10" :offset="2">
        <BannerComponent/>
      </el-col>
      <el-col :xs="20" :sm="20" :md="12" :lg="8" :xl="8" :offset="offsetResponsive()">
        <FinderComponents/>
      </el-col>
      </el-row>
      <ServiceComponents/>
      <FaqComponents/>
      <CountComponents/>
      <FooterComponents/>
  </div>
</template>
<script>
import BannerComponent from '@/components/BannerComponents.vue'
import CountComponents from '@/components/CountComponents.vue'
import FaqComponents from '@/components/FaqComponents.vue'
import FinderComponents from '@/components/FinderComponents.vue'
import FooterComponents from '@/components/FooterComponents.vue'
import HeaderComponent from '@/components/HeaderComponent'
import ServiceComponents from '@/components/ServiceComponents.vue'
// import Upload from '@/components/uploadImage/dynamicUploadImage.vue'
export default {
  name: 'HomePage',
  data () {
    return {
      windowWidth: window.innerWidth
    }
  },
  components: {
    HeaderComponent,
    BannerComponent,
    FinderComponents,
    ServiceComponents,
    FaqComponents,
    CountComponents,
    FooterComponents
  },
  created () {
  },

  methods: {
    offsetResponsive () {
      if (this.windowWidth > 900) {
        return 1
      } else {
        return 2
      }
    }
  }
}

</script>
