<template>
  <div class="finder-layout">
    <div class="finder_title">
      <p class="text_size">
        Танд шаардлагатай бараа бүтээгдэхүүнийг <br/>
        <b> 5-7 хоногт</b>
      </p>
    </div><br/>
    <div class="finder_description">
     <p class="text_size_grey">
      Манайх танд найдвартай, <br /> найрсаг үйлчилгээг санал болгож байна.
     </p>
    </div>
    <div class="m-t-40">
      <el-input placeholder="Та ачаанийхаа дугаар эсвэл утасны дугаараа оруулна уу" v-model="track_code" class="input-with-select">
        <el-button slot="append" icon="el-icon-search" @click="getTrackCodeDetail()" :disabled="!track_code"><b>Хайх</b></el-button>
      </el-input>
    </div>
    <div>
    <el-row class="checkbox_zone m-t-40 content_justify_center space_around" >
        <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
            <img src="@/assets/check.png" width="10px" alt="Logo" /> <span>100% баталгаатай</span>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
            <img src="@/assets/check.png" width="10px" alt="Logo" /> Эвдрэлгүй
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6">
            <img src="@/assets/check.png" width="10px" alt="Logo" /> Хүргэлттэй
        </el-col>
    </el-row>
</div>
<div class="m-t-40">
    <el-button class="track_code_button" @click="trackCodeAddView()"> <i class="el-icon-circle-plus-outline el-icon-right"></i>Track code бүртгэх</el-button>
    </div>
    <div class="m-t-40">
        <el-row class="checkbox_zone m-t-40 m-l-10">
        <el-col class="line" :xs="18" :sm="18" :md="20" :lg="20" :xl="20">
            <hr>
        </el-col>
        <el-col :xs="6" :sm="6" :md="4" :lg="4" :xl="4" class="finder_icons">
            <a target="_blank" href="https://www.instagram.com/viva_cargo_mongolia/?utm_source=ig_web_button_share_sheet"><i class="fa-brands fa-instagram m-l-10" style="color: #E4405F;"></i></a>
            <a target="_blank" href="https://www.facebook.com/profile.php?id=100070131997091"><i class="fa-brands fa-square-facebook m-l-20" style="color: #1877F2"></i></a>
        </el-col>
      </el-row>
    </div>
    <div>
        <TrackCodeDetailDialog v-if="this.$root.trackCodeDetailDialog" :trackCodeDetailProp=track_code_detail />
        <TrackCodeAddDialog v-if="this.$root.trackCodeAddDialog" :productTypeProp=productTypes />
    </div>
  </div>
</template>

<script>
import services from '@/helpers/services'
import TrackCodeDetailDialog from './Dialog/trackCodeDetailDialog.vue'
import TrackCodeAddDialog from './Dialog/trackCodeAddDialog.vue'

export default {
  name: 'HeaderComponent',
  components: {
    TrackCodeDetailDialog,
    TrackCodeAddDialog
  },
  data () {
    return {
      track_code: '',
      phone1: null,
      track_code_detail: {
        Progress: {
        },
        errorrMessage: null
      },
      productTypes: []
    }
  },
  created () {
    this.getProductType()
  },
  methods: {
    getTrackCodeDetail () {
      this.$root.fullscreenLoading = true
      services.searchTrackCode(this.track_code)
        .then((response) => {
          if (response.type === 'success') {
            this.track_code_detail = response.data
            this.$root.trackCodeDetailDialog = true
            this.$root.fullscreenLoading = false
          } else {
            this.track_code_detail.track_code = this.track_code
            this.track_code_detail.errorrMessage = response.data
            this.$root.trackCodeDetailDialog = true
            this.$root.fullscreenLoading = false
          }
        })
    },
    trackCodeAddView () {
      this.$root.trackCodeAddDialog = true
    },
    getProductType () {
      services.getProductType()
        .then((response) => {
          this.productTypes = response
        })
        .catch((error) => {
          console.error('Алдаа:', error.message)
        })
    }
  }
}
</script>
<style>
@media all and (min-width: 991px) {
  .text_size {
    font-size: 32px;
  }
}
@media all and (max-width: 991px) {
  .text_size{
    font-size: 16px;
  }
}
@media all and (min-width: 991px) {
  .text_size_grey {
    font-size: 30px;
  }
}
@media all and (max-width: 991px) {
  .text_size_grey{
    font-size: 12px;
  }
}
.finder_icons{
    color: rgb(134, 134, 134);
    font-size: 20px;
}
.finder_icons i{
    margin-top: 10px;
}
hr {
  border: none;          /* Хүрээг арилгах */
  height: 2px;           /* Зураасны зузаан */
  background-color: rgb(226, 226, 226); /* Зураасны өнгө */
  margin: 20px 0;
  width: 100%;      /* Дээд доод зай */
}
.track_code_button{
    background-color: #007AFF !important;
    color: white !important;
    width: 100%;
    font-family: 'Geologica', sans-serif;
    height: 60px;
    font-weight: bolder;
    border-radius: 10px !important;
}
.checkbox_zone{
    align-items: center;
    font-family: 'Geologica', sans-serif;
    font-weight: 500;
}
.el-input-group--append .el-input__inner, .el-input-group__prepend{
    border: none;
    color: gray;
    font-family: 'Geologica', sans-serif;
    height: 60px;
    box-shadow: 0px 10px 15px #e1e1e1;
}
.el-input-group__append{
    background: #007AFF !important;
    color: white !important;
    font-family: 'Geologica', sans-serif;
    font-weight: bold;
}
  .finder-layout {
    float: right;
    margin-top: 20%;
  }

  .finder_title {
    text-align: right;
    font-family: 'Geologica', sans-serif;
    line-height: 1.5;
    word-break: break-word;
  }

  .finder_description {
    font-family: 'Geologica', sans-serif;
    color: rgb(182, 182, 182);
    text-align: right;
    font-size: 18px;
    font-weight: 200;
    line-height: 1.5;
  }

</style>
